<template>
 
       <fragment> 
        <th>Submitted Date</th>        
         <th>Reg Date</th>
                               <th style="border-left:none !important;">Phone</th>
                                <th>Age</th>
                                <th>Sex</th>
                                <th>Address</th>
                                <th>Symptoms</th>
                                <th>Referal Date</th>
                                <th>Ht</th>
                                <th >Wt</th>
                                <th>Refby</th>
                                <th>Refperson</th>
                                <th>Refto</th>
                                <th>Activity</th>
                                <th>Reftyp</th>
                                <th>RiskFac</th>
                                
                              
        </fragment>                     
                    
                  
</template>

<script>



export default {
  name: 'Header',
  data () {
    return {
    }
  },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    
     
</style>
