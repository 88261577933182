<template>
  <div v-if="tb_conclusion" class="card card-content">
    <h6>TB Conslusion</h6>

    <form>
      <div class="row">
        <div class="input-field col s12">
          <span>Referred Diagnostic / Treatment Entity</span>
          <select v-model="tb_conclusion.referred_diagnostic">
            <option value selected>Choose your option</option>
            <option>Township Heath Department</option>
            <option>Station Health Department</option>
            <option>PSI</option>
            <option>SCH</option>
            <option>MMA</option>
            <option>62 OPD (UMTBC)</option>
            <option>AHRN</option>
            <option>TBH</option>
            <option>Private for profit</option>
            <option>Other</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <span>TB Status</span>
          <select v-model="tb_conclusion.tb_status">
            <option value selected>Choose your option</option>
            <option value="1">TB</option>
            <option value="2">No TB</option>
            <option value="3">MDRTB</option>
            <option value="4">Waiting for TB Conclusion</option>
          </select>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <span>Union Temporary Code</span>
            <input type="text" v-model="tb_conclusion.union_temporary_code" />
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <span>TB Code (Actual) - Treatment Entity</span>
            <select v-model="tb_conclusion.new_tb_code_type">
              <option value selected>Choose your option</option>
              <option>PSI</option>
              <option>MMA</option>
              <option>SCH</option>
              <option>Tsp</option>
              <option>UMTBC</option>
              <option>AHRN</option>
            </select>
          </div>
          <div
            class="input-field col s12"
            v-show="
              tb_conclusion.new_tb_code_type &&
              tb_conclusion.new_tb_code_type !== 'UMTBC'
            "
          >
            <span>TB Code (Actual) - Treatment Township</span>

            <select v-model="tb_conclusion.new_tb_code_township">
              <option value selected>Choose your option</option>
              <option v-for="item in townships" :key="item.id">{{ item.short_name }}</option>
            </select>
          </div>
          <div
            class="input-field col s12"
            v-show="
              tb_conclusion.new_tb_code_type === 'UMTBC' ||
              tb_conclusion.new_tb_code_township
            "
          >
            <span>TB Code (Actual) - Code</span>
            <input type="text" v-model="tb_conclusion.new_tb_code" />
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <span>Temporary Code (Other)</span>
            <input type="text" v-model="tb_conclusion.other_temporary_code" />
          </div>
        </div>

        <div class="input-field col s12" v-show="tb_conclusion.tb_status == 2">
          <span>TPT Status</span>
          <select v-model="tb_conclusion.tpt_status">
            <option value selected>Choose your option</option>
            <option value="0">No TPT</option>
            <option value="1">TPT</option>
          </select>
        </div>

        <div class="row">
          <div class="input-field col s12">
            <span>MDR TB Code / TPT Code</span>
            <input type="text" v-model="tb_conclusion.mdr_tb_code" />
          </div>
        </div>
        <div class="input-field col s12" v-show="show1 && showUnknown">
          <span>Smoking status </span>

          <select v-model="tb_conclusion.smoking_status">
            <option value selected>Choose your option</option>
            <option value="3">Past</option>
            <option value="2">Never</option>
            <option value="1">Current</option>
            <option value="4">Unknown</option>
          </select>
        </div>
        <div
          class="input-field col s12"
          v-show="show1 && showUnknown === false"
        >
          <span>Smoking status </span>

          <select v-model="tb_conclusion.smoking_status">
            <option value selected>Choose your option</option>
            <option value="3">Past</option>
            <option value="2">Never</option>
            <option value="1">Current</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Urban or Rural</span>
          <select v-model="tb_conclusion.urban_rural">
            <option value selected>Choose your option</option>
            <option value="1">Urban</option>
            <option value="2">Rural</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Treatment Status</span>
          <select
            v-model="tb_conclusion.treatment_status"
            @change="treatmentStatusChange"
          >
            <option value selected>Choose your option</option>
            <option value="1">Received TB Treatment</option>
            <option value="2">Refer to other township</option>
            <option value="3">Initial Loss</option>
            <option value="4">Died before Treatment</option>
          </select>
        </div>

        <div class="input-field col s12" v-show="show2">
          <span>Type of TB Patients</span>
          <select v-model="tb_conclusion.tb_type">
            <option value selected>Choose your option</option>
            <option value="1">New</option>
            <option value="2">Relapse</option>
            <option value="3">Treatment after failure</option>
            <option value="4">Treatment after loss to follow up</option>
            <option value="5">Other previously treated</option>
            <option value="6">Previous treatment history unknown</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Defined TB Type</span>
          <select v-model="tb_conclusion.tb_site">
            <option value selected>Choose your option</option>
            <option value="1">Pulmonary BC</option>
            <option value="2">Extra Pulmonary BC</option>
            <option value="3">Pulmonary CD</option>
            <option value="4">Extra Pulmonary CD</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1 && show2">
          <span>Treatment Regimen</span>
          <select v-model="tb_conclusion.treatment_reg">
            <option value selected>Choose your option</option>
            <option value="1">Initial Regimen</option>
            <option value="2">Retreatment Regiment</option>
            <option value="3">Childhood Regiment</option>
            <option value="4">MDRTB Regiment</option>
            <option value="5">Modified Regimen</option>
          </select>
        </div>
        <div class="input-field col s12">
          <span>Treatment Start Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="tb_conclusion.start_date"
            class="datepicker"
            readonly
          />
        </div>

        <div class="input-field col s12" v-show="show1">
          <span>DM status</span>
          <select v-model="tb_conclusion.dm_status">
            <option value selected>Choose your option</option>
            <option value="1">Yes</option>
            <option value="2">No</option>
            <option value="3">Unknown</option>
          </select>
        </div>
        <div class="row">
          <div class="input-field col s12" v-show="show1">
            <span>HIV status</span>
            <select v-model="tb_conclusion.hiv_status">
              <option value selected>Choose your option</option>
              <option value="1">Positive</option>
              <option value="2">Negative</option>
              <option value="3">Unknown</option>
            </select>
          </div>
          <div class="input-field col s12" v-show="show1 && showHIV">
            <span>CPT Date</span>
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              v-model.lazy="tb_conclusion.cpt_date"
              class="datepicker"
              readonly
            />
          </div>
          <div class="input-field col s12" v-show="show1 && showHIV">
            <span>ART Date</span>
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              v-model.lazy="tb_conclusion.art_date"
              class="datepicker"
              readonly
            />
          </div>
          <div class="input-field col s12" v-show="show1 && showHIV">
            <span>HIV Care</span>
            <select v-model="tb_conclusion.hiv_care">
              <option value selected>Choose your option</option>
              <option value="1">IHC</option>
              <option value="2">NAP</option>
              <option value="3">Other</option>
            </select>
          </div>
          <div class="input-field col s12" v-show="show1 && showHIV">
            <span>HIV Code</span>
            <input v-model="tb_conclusion.hiv_code" />
          </div>
        </div>

        <div
          class="input-field col s12"
          v-show="(show1 && show2) || tb_conclusion.tpt_status == 1"
        >
          <span>Treatment outcome</span>
          <select v-model="tb_conclusion.treatment_outcome">
            <option value selected>Choose your option</option>
            <option value="1">Cured</option>
            <option value="2">Treatment complete</option>
            <option value="3">Treatment failure</option>
            <option value="4">Died</option>
            <option value="5">Lost to follow up</option>
            <option value="6">Not evaluated</option>
            <option value="7">Moved to second line treatment</option>
          </select>
        </div>
        <div
          class="input-field col s12"
          v-show="
            (show2 && this.tb_conclusion.treatment_outcome) ||
            tb_conclusion.tpt_status == 1
          "
        >
          <span>Outcome Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="tb_conclusion.outcome_date"
            class="datepicker"
            readonly
          />
        </div>

        <div class="input-field col s12">
          <span>Remark</span>
          <textarea
            class="materialize-textarea"
            v-model="tb_conclusion.remark"
          ></textarea>
        </div>

        <div class="row center">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
    <div
      v-show="
        tb_conclusion.code != 0 ||
        // tb_conclusion.code != null ||
        tb_conclusion.other_tb_code != 0 ||
        // tb_conclusion.other_tb_code != null ||
        tb_conclusion.temporary_code != 0 
        // tb_conclusion.temporary_code != null
      "
    >
      <h6>Old TB Code</h6>
      <div class="row">
        <div class="input-field col s12">
          <span>TB / MDRTB / TPT code (Tsp)</span>
          <input type="text" v-model="tb_conclusion.code" disabled />
        </div>
      </div>
      <div class="input-field col s12">
        <span>TB Code (Other) </span>
        <input type="text" v-model="tb_conclusion.other_tb_code" disabled />
      </div>
      <div class="row">
        <div class="input-field col s12">
          <span>Temporary code</span>
          <input type="text" v-model="tb_conclusion.temporary_code" disabled />
        </div>
      </div>
    </div>
  </div>
  <new-tb-conclusion v-else></new-tb-conclusion>
</template>

<script>
import NewTBConclusion from "@/components/Patient/Fill/TBConclusion/NewTBConclusion.vue";
export default {
  name: "TBConclusion",
  data() {
    return {};
  },
  components: {
    "new-tb-conclusion": NewTBConclusion,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    submit() {
      var data = {
        tb_status: this.tb_conclusion.tb_status,
        treatment_status: this.tb_conclusion.treatment_status,
        new_tb_code_type: this.tb_conclusion.new_tb_code_type,
        new_tb_code_township: this.tb_conclusion.new_tb_code_township,
        new_tb_code: this.tb_conclusion.new_tb_code,
        union_temporary_code: this.tb_conclusion.union_temporary_code,
        mdr_tb_code: this.tb_conclusion.mdr_tb_code,
        other_temporary_code: this.tb_conclusion.other_temporary_code,
        tb_type: this.tb_conclusion.tb_type,
        tb_site: this.tb_conclusion.tb_site,
        treatment_reg: this.tb_conclusion.treatment_reg,
        start_date: this.tb_conclusion.start_date,
        treatment_outcome: this.tb_conclusion.treatment_outcome,
        dm_status: this.tb_conclusion.dm_status,
        smoking_status: this.tb_conclusion.smoking_status,
        urban_rural: this.tb_conclusion.urban_rural,
        hiv_status: this.tb_conclusion.hiv_status,
        referred_diagnostic: this.tb_conclusion.referred_diagnostic,
        remark: this.tb_conclusion.remark,
      };

      if (data.hiv_status == 1) {
        data.hiv_care = this.tb_conclusion.hiv_care;
        data.hiv_code = this.tb_conclusion.hiv_code;
        data.cpt_date = this.tb_conclusion.cpt_date;
        data.art_date = this.tb_conclusion.art_date;
      }

      if (this.tb_conclusion.tb_status == 2) {
        data.tpt_status = this.tb_conclusion.tpt_status;
      } else {
        data.tpt_status = 0;
      }

      if (!this.show1) {
        data.treatment_status = null;
        data.smoking_status = null;
        data.dm_status = null;
        data.urban_rural = null;
      }

      if (!this.show2 && this.tb_conclusion.tpt_status != 1) {
        data.tb_type = null;
        data.treatment_reg = null;
        data.start_date = null;
        data.treatment_outcome = null;
        data.hiv_status = null;
      }

      if (data.treatment_outcome) {
        data.outcome_date = this.tb_conclusion.outcome_date;
      }

      data.id = this.patient.id;
      this.$store.dispatch("submitTBConclusion", data);
    },
  },

  computed: {
    townships() {
      return this.$store.getters.all_townships;
    },
    patient() {
      return this.$store.getters.patient;
    },
    // is_add_done() {
    //   if (this.patient.metadata.refer_from == 7) {
    //     return true;
    //   }
    //   if (this.patient.metadata.refer_from == 5) {
    //     return true;
    //   } else {
    //     if (
    //       (this.patient.chest_xray && this.patient.chest_xray.done == 1) ||
    //       (this.patient.sputum && this.patient.sputum.done) == 1 ||
    //       (this.patient.gene_xpert && this.patient.gene_xpert.done == 1) ||
    //       (this.patient.tuberculinskin &&
    //         this.patient.tuberculinskin.done == 1) ||
    //       (this.patient.fnac && this.patient.fnac.done == 1)
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    not_no_tb() {
      if (this.patient.metadata.refer_from == 5) {
        return false;
      } else {
        if (
          // this.patient.sputum.result == 2 ||
          this.patient.gene_xpert.result == 2 ||
          this.patient.fnac.result == 2
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    tb_conclusion() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.tb_conclusion;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }

      return data;
    },

    buttonpass() {
      if (!this.tb_conclusion.tb_status) {
        return false;
      } else if (
        ((this.tb_conclusion.tb_status == 1 ||
          this.tb_conclusion.tb_status == 3) &&
          (!this.tb_conclusion.smoking_status ||
            !this.tb_conclusion.urban_rural ||
            !this.tb_conclusion.hiv_status)) 
        // (this.tb_conclusion.new_tb_code &&
        //   this.tb_conclusion.new_tb_code.length < 6) ||
        // (this.tb_conclusion.union_temporary_code &&
        //   this.tb_conclusion.union_temporary_code.length != 6)
      ) {
        return false;
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
    showUnknown() {
      if (parseInt(this.tb_conclusion.treatment_status) === 1) return false;
      return true;
    },
    show1() {
      if (
        this.patient.tb_conclusion.tb_status != 0 &&
        this.patient.tb_conclusion.tb_status != 2 &&
        this.patient.tb_conclusion.tb_status != 4
      ) {
        return true;
      } else {
        return false;
      }
    },
    show2() {
      if (
        this.patient.tb_conclusion.tb_status != 2 &&
        this.patient.tb_conclusion.treatment_status
      ) {
        if (
          this.patient.tb_conclusion.treatment_status == 1 ||
          this.patient.tb_conclusion.treatment_status == 2 ||
          this.patient.tb_conclusion.treatment_status == 3 ||
          this.patient.tb_conclusion.treatment_status == 4
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showHIV() {
      if (this.patient.tb_conclusion.hiv_status == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
