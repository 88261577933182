<template>
  <div class="cutsom_container">
    <div class="row">
      <br />
      <back-container
        :link="'/dots/visitdate/' + $route.params.id"
      ></back-container>
      <visit-date-detail
        @close="detailShow = false"
        v-bind:show="detailShow"
        v-bind:detailData="detailData"
      ></visit-date-detail>
      <date-edit-modal
        @close="dateEditShow = false"
        v-bind:show="dateEditShow"
        v-bind:detailData="detailData"
      ></date-edit-modal>
      <edit-dots-visit-data
        @close="editInfoModalShow = false"
        v-bind:show="editInfoModalShow"
        v-bind:detailData="detailData"
      ></edit-dots-visit-data>
    </div>
    <div class="row">
      <div class="col m6">
        <div class="card card-content">
          <table class="app-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>TB Code</th>
                <th>DOTS Start Date</th>
                <th>TB Year</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ data.patient.name }}</td>
                <td>{{ tbCode }}</td>
                <td>{{ data.patient.dots_startdate }}</td> 
                <td>{{ data.patient.tb_year }}</td> 
                <td>{{ data.patient.phone }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col m6">
        <div class="card card-content">
          <ul class="collapsible" style="margin-bottom: 20px">
            <li>
              <div class="collapsible-header">
                <i class="material-icons">settings</i>Setting
              </div>
              <div class="collapsible-body">
                <div class="row">
                  <div class="col s6">
                    <span>Start Date</span>
                    <input
                      v-model.lazy="start_date"
                      type="text"
                      class="datepicker"
                    />
                  </div>
                  <div class="col s6">
                    <span>End Date</span>
                    <input
                      v-model.lazy="end_date"
                      type="text"
                      class="datepicker"
                    />
                  </div>
                  <div class="col s12 center">
                    <br />
                    <button @click="btnSearch" class="btn blue">Search</button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <table class="app-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Date</th>
                <th>Status</th>
                <th>Volunteer</th>
                <th>FO Edits</th>
                <th>Pill Count</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, index) in data.visit_dates" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.date }}</td>
                <td>
                  {{ getStatus(item.status) }}
                </td>
                <td>
                  <span v-if="item.volunteer"
                    >{{ item.volunteer.code }} ({{ item.volunteer.name }})</span
                  >
                </td>
                <td>
                  <router-link
                    :to="'/patient/dots/edit-history/' + item.id"
                    class="blue-text darken-2"
                    >{{ item.edit_histories.length }}</router-link
                  >
                </td>
                <td>{{ item.status == 1 ? item.pill_count : "" }}</td>
                <td>
                  <button
                    @click="viewVisitDateDetail(item)"
                    class="btn blue darken-3"
                  >
                    <i class="material-icons">details</i>
                  </button>
                  <button
                    style="margin-left: 10px"
                    @click="editDate(item)"
                    class="btn blue darken-3"
                  >
                    <i class="material-icons">edit</i>
                  </button>
                  <button
                    style="margin-left: 10px"
                    @click="editVisitDate(item)"
                    class="btn green darken-3"
                  >
                    <i class="material-icons">brush</i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import VisitDateDetail from "./VisitDateDetail.vue";
import DateEditModal from "./DateEditModal.vue";
import EditDotsVisitDate from "./EditDotsVisitDate.vue";
import { dayDiff } from "./../../../misc/visit_date_service";

export default {
  data() {
    return {
      detailShow: false,
      detailData: null,
      start_date: "",
      end_date: "",
      dateEditShow: false,
      editInfoModalShow: false,
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });

    this.getData();
  },
  updated() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  computed: {
    data() {
      return this.$store.getters.dots_visitdates;
    },
    tbCode() {
  const patient = this.data?.patient?.patient;
  const tbConclusion = patient?.tb_conclusion;

  if (tbConclusion?.union_temporary_code_plain) {
    return tbConclusion.union_temporary_code_plain;
  }

  if (tbConclusion?.new_tb_code_type && tbConclusion?.new_tb_code_township && tbConclusion?.new_tb_code) {
    return `${tbConclusion.new_tb_code_type}${tbConclusion.new_tb_code_township}${tbConclusion.new_tb_code}`;
  }

  return this.data?.patient?.tb_code || '';
},
    loading() {
      return this.$store.getters.dots_visitdates_loading;
    },
    action_loading() {
      return this.$store.getters.dots_visitdates_action_loading;
    },
    action_finish() {
      return this.$store.getters.dots_visitdates_action_finish;
    },
  },
  methods: {
    deleteData(id) {
      this.$store.dispatch("deleteDOTSVisitDate", {
        id: id,
      });
    },
    getStatus(id) {
      switch (parseInt(id)) {
        case 1:
          return "Visited";
          break;
        case 2:
          return "Cannot Connect";
          break;
        case 0:
          return "Pending";
          break;
      }
    },
    viewVisitDateDetail(data) {
      this.detailShow = true;
      this.detailData = data;
    },
    btnSearch() {
      var params = this.$route.query;

      this.$router.push({
        path: `/dots/visitdate/all/${this.$route.params.id}`,
        query: {
          start_date: this.start_date,
          end_date: this.end_date,
        },
      });
    },
    getData() {
      this.$store.dispatch("getSideEffects");
      var data = {
        patient_id: this.$route.params.id,
      };
      if (this.$route.query.start_date && this.$route.query.end_date) {
        data.start_date = this.$route.query.start_date;
        data.end_date = this.$route.query.end_date;
      }
      this.$store.dispatch("getDOTSVisitDate", data);
    },
    editDate(data) {
      this.dateEditShow = true;
      this.detailData = data;
    },
    editVisitDate(data) {
      this.detailData = data;
      this.editInfoModalShow = true;
    },
    canEditData(data) {
      if (dayDiff(new Date(data.date), new Date()) < 31) return true;
      else return false;
    },
  },
  watch: {
    action_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Deleted",
        });
      }
    },
    $route(to, from) {
      this.getData();
    },
  },
  components: {
    "back-container": BackContainer,
    "visit-date-detail": VisitDateDetail,
    "date-edit-modal": DateEditModal,
    "edit-dots-visit-data": EditDotsVisitDate,
  },
};
</script>

<style lang="scss" scoped>
.cutsom_container {
  padding-left: 35px;
  padding-right: 35px;
}
</style>
