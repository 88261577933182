<template>
  <fragment>
    <td>{{ data.submitted_date }}</td>
    <td>{{ data.presumptive_tb.registeration_date }}</td>
    <td style="border-left:none !important;">{{ data.phone }}</td>
    <td>{{ data.age }}</td>
    <td>{{ gender }}</td>
    <td>{{ data.address }}</td>
    <td v-html="symptons"></td>
    <td>{{ data.submitted_date }}</td>
    <td>{{ data.height }} inches</td>
    <td>{{ data.weight }} kg</td>
    <td v-if="data.metadata.refer_from">{{ refer_from }}</td>
    <td v-else>-</td>
    <td>{{ ref_person }}</td>
    <td v-if="data.metadata.submit_department">{{ submit_department }}</td>
    <td v-else>-</td>

    <td v-if="data.metadata.found_place">{{ found_place }}</td>
    <td v-else>-</td>

    <td v-if="data.metadata.submit_type">
      {{ data.metadata.submit_type.eng_name }}
    </td>
    <td v-else>-</td>

    <td>
      {{ data.presumptive_tb.risk_factors | risk_factor_display }}
    </td>
  </fragment>
</template>

<script>
export default {
  name: "Body",
  props: ["data"],
  data() {
    return {};
  },
  filters: {
    risk_factor_display(value) {
      var val = "";
      for (var i in value) {
        val += value[i].name + ",";
      }

      return val;
    },
  },

  computed: {
    gender() {
      if (this.data.gender == 1) {
        return "Male";
      } else {
        return "Female";
      }
    },

    found_place() {
      var value = this.data.metadata.found_place.eng_name;
      if (this.data.metadata.patient_found_place_id == 2) {
        value += " ( " + this.data.metadata.pfp_description + " ) ";
        if (this.data.metadata.sub_pfp == 1) {
          value += " (Household) ";
        } else if (this.data.metadata.sub_pfp == 2) {
          value += " (Non-household) ";
        }
      }
      return value;
    },

    submit_department() {
      var value = this.data.metadata.submit_department.eng_name;
      if (this.data.metadata.patient_submit_department_id == 3) {
        value += " ( " + this.data.metadata.submit_s_c_c.name + " ) ";
      }
      return value;
    },

    symptons() {
      var values = "";
      this.data.symptons.map((value) => {
        values += value.eng_name + ",";
      });

      return values;
    },

    refer_from() {
      if (this.data.metadata.refer_from == 1) {
        return "PICTS volunteer";
      } else if (this.data.metadata.refer_from == 2) {
        return "PICTS GP";
      } else if (this.data.metadata.refer_from == 3) {
        return "PICTS phamacy";
      } else if (this.data.metadata.refer_from == 7) {
        return "PICTS LTBI";
      } else {
        return "Others";
      }
    },

    ref_person() {
      if (this.data.metadata.refer_from == 1) {
        return this.data.volunteer.name + " (" + this.data.volunteer.code + ")";
      } else if (
        this.data.metadata.refer_from == 2 ||
        this.data.metadata.refer_from == 3
      ) {
        return this.data.metadata.refer_description;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
