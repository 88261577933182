<template>
  <div class="row">
    <div class="col m8 offset-m2">
      
      <div class="loading" v-if="loading">
        <pre-loader></pre-loader>
      </div>
      <section v-else>
        <div v-show="data.is_editable">
      <back-container link="/patient/dots/list"></back-container>
      </div>
        <setting-bar v-show="data.is_editable"></setting-bar>
        <div class="card card-content">
          <h6>Presumptive Information</h6>

          <div class="input-field">
            <span>Name</span>
            <input type="text" v-model="data.name" readonly="true" />
          </div>
          <div class="input-field">
            <span>Phone</span>
            <input type="text" v-model="data.phone" readonly="true" />
          </div>
          <div class="input-field">
            <span>Age</span>
            <input type="text" v-model="data.age" readonly="true" />
          </div>
          <div class="input-field">
            <span>Gender</span>
            <select v-model="data.gender" disabled>
              <option value="1">Male</option>
              <option value="2">Female</option>
            </select>
          </div>
          <div class="input-field">
            <span>Address</span>
            <input type="text" v-model="data.address" readonly="true" />
          </div>
          <div class="input-field">
            <span>Weight</span>
            <input type="text" v-model="data.weight" />
          </div>
           <div class="input-field">
            <span>Volunteer</span>
            <select v-model="data.volunteer_id" disabled>
              <option
                v-for="item in data.volunteers"
                :value="item.id"
                :key="item.id"
                >{{ item.name }} ({{ item.code }})</option
              >
            </select>
          </div>
         <div class="input-field" v-if="data.patient && data.is_new == 0 && data.patient.tb_conclusion &&  data.patient.tb_conclusion.tb_status != 2">
            <span>Actual TB Code</span>
            <input
              type="text"
              :value="
                `${data.patient.tb_conclusion.new_tb_code_type}-${data.patient.tb_conclusion.new_tb_code_township}/${data.patient.tb_conclusion.new_tb_code}`
              "
              readonly="true"
            />
          </div>
          <div class="input-field" v-if="data.patient  && data.is_new == 0 && data.patient.tb_conclusion && data.patient.tb_conclusion.tb_status != 2">
            <span>Union Temporary Code</span>
            <input
              type="text"
              :value="`${data.patient.tb_conclusion.union_temporary_code}`"
              readonly="true"
            />
            <div class="input-field" v-if="data.patient  && data.is_new == 0 && data.patient.tb_conclusion && data.patient.tb_conclusion.tb_status != 2">
              <span> Temporary Code (Other)</span>
              <input
                type="text"
                :value="`${data.patient.tb_conclusion.other_temporary_code}`"
                readonly="true"
              />
            </div>
          </div>
          <div class="input-field" v-if="data.patient  && data.is_new == 0 && data.patient.tb_conclusion">
            <span> MDR TB Code / TPT Code</span>
            <input
              type="text"
              :value="`${data.patient.tb_conclusion.mdr_tb_code}`"
              readonly="true"
            />
          </div>
           <div class="input-field" v-show="data.patient  && data.is_new == 0 && data.patient.tb_conclusion && data.patient.tb_conclusion.tb_status==2">
          <span> TPT Status</span>
          <input
            type="text"
            :value="
              `${
                data.patient?data.patient.tb_conclusion.tpt_status === 0 ? 'No TPT' : 'TPT':''
              }`
            "
            readonly="true"
          />
        </div>

          <div class="input-field" v-show="data.is_new == 0 && data.patient.tb_conclusion.tb_status != 2">
            <span>Defiend TB Type</span>
            <select v-model="data.defined_tb_type_id" disabled>
              <option value="1">Pulmonary BC</option>
              <option value="2">Extra Pulmonary BC</option>
              <option value="3">Pulmonary CD</option>
              <option value="4">Extra Pulmonary CD</option>
            </select>
          </div>
          <div class="input-field" v-show="data.is_new == 0 && data.patient.tb_conclusion.tb_status != 2">
            <span>Treatment Regimen</span>
            <select v-model="data.treatment_reg_id" disabled>
              <option value="1">Initial Regimen</option>
              <option value="2">Retreatment Regiment</option>
              <option value="3">Childhood Regiment</option>
              <option value="4">MDRTB Regiment</option>
            </select>
          </div>
      
          <div class="input-field" v-if="data.is_new == 0">
            <span>Treatment Start Date</span>
            <input
              type="text"
              v-model.lazy="data.patient.tb_conclusion.start_date"
              readonly="true"
            />
          </div>
            <div class="input-field">
          <span>Actual Treatment Start Date</span>
          <input
            type="text"
            v-model.lazy="data.actual_treatment_startdate"
            class="datepicker"
          />
            </div>
           <div class="input-field">
            <span>Treatment End Date</span>
            <input
              type="text"
              class="datepicker"
              v-model.lazy="data.treatment_enddate"
              readonly="true"
            />
          </div>

        <div class="input-field" v-if="data.is_new == 0">
            <span>Treatment Outcome</span>
            <select
              v-model.lazy="data.patient.tb_conclusion.treatment_outcome"
              v-model="data.patient.tb_conclusion.treatment_outcome"
              disabled
            >
             <option value="1">Cured</option>
            <option value="2">Treatment complete</option>
            <option value="3">Treatment failure</option>
            <option value="4">Died</option>
            <option value="5">Lost to follow up</option>
            <option value="6">Not evaluated</option>
            <option value="7">Moved to second line treatment</option>
            </select>
          </div>

          <div class="input-field" v-if="data.is_new == 1">
            <span>Treatment Outcome</span>
            <select
              v-model.lazy="data.treatment_outcome_id"
              v-model="data.treatment_outcome_id"
            >
             <option value="1">Cured</option>
            <option value="2">Treatment complete</option>
            <option value="3">Treatment failure</option>
            <option value="4">Died</option>
            <option value="5">Lost to follow up</option>
            <option value="6">Not evaluated</option>
            <option value="7">Moved to second line treatment</option>
            </select>
          </div>

          <div class="input-field" v-if="data.treatment_outcome_id == 5 || data.patient.tb_conclusion.treatment_outcome ">
            <span>Reason for lost to follow up</span>
            <input type="text" v-model="data.reason_lost_to_follow_up" />
          </div>

          <div class="input-field" v-if="data.treatment_outcome_id == 5 || data.patient.tb_conclusion.treatment_outcome ">
            <span>Lost to follow up  category</span>
            <select v-model="data.lost_to_follow_up_category">
              <option
                v-for="item in [
                  { id: 1, name: 'Socio-economic' },
                  { id: 2, name: 'TB drug side-effect' },
                  { id: 3, name: 'Other' },
                ]"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>

          <div class="input-field"  v-if="data.is_new == 0">
            <span>Outcome Date</span>
            <input
              type="text"
              v-model.lazy="data.patient.tb_conclusion.outcome_date"
              readonly="true"
            />
          </div>
           <div class="input-field"  v-if="data.is_new == 1">
            <span>Outcome Date</span>
            <input
              type="text"
               class="datepicker"
              v-model.lazy="data.outcome_date"
            />
          </div>
          <div class="input-field">
            <span>DOTS Start Date</span>
            <input
              type="text"
              class="datepicker"
              v-model.lazy="data.dots_startdate"
              readonly="true"
            />
          </div>
        
           <div class="input-field" v-show="data.type == 2">
            <span>Project</span>
            <select v-if="data.project_id" v-model="data.project_id" @change="getTownshipByProject">
              <option
                v-for="item in [
                  { id: 1, name: 'PICTS 1' },
                  { id: 2, name: 'PICTS 2' },
                ]"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>
          <div class="input-field" v-if="data.project_id != null">
            <span>Township</span>
            <select v-model="data.other_township_id">
              <option
                v-for="item in townships"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>
          <div class="input-field" v-show="data.type == 2">
            <span>Union TB Code</span>
            <input type="text" v-model="data.temporary_code" />
          </div> 

           <div class="input-field"  v-if="data.is_new == 1">
          <span>Township TB Code</span>
          <input type="text" v-model="data.tb_code" />
        </div>
        <div class="input-field"  v-if="data.is_new == 1">
          <span>Type</span>
          <select v-model="data.type">
            <option
              v-for="item in [
                { id: 1, type: 'TPT' },
                { id: 2, type: 'Other Township' },
              ]"
              :value="item.id"
              :key="item.id"
              >{{ item.type }}</option
            >
          </select>
        </div>

          <div class="input-field">
            <span>Completed DOT supervision (Y/N)</span>
            <select v-model="data.finish" disabled>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
          </div>
          <div class="input-field">
            <span>DOTS End Date</span>
            <input
              type="text"
              class="datepicker"
              v-model.lazy="data.dots_enddate"
              disabled
            />
          </div>
         
          <div class="input-field" >
            <span>Using DOTS APP</span>
            <select v-model="data.dots_app_status" disabled>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <div class="input-field">
            <span>DOTS outcome verification form received</span>
            <select v-model="data.dots_outcome_verification_form">
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </div>
          <div class="input-field">
            <span>Type of form received</span>
            <input type="text" v-model="data.dots_form_type"  />
          </div>
            <div class="input-field">
            <span>Remark</span>
            <input type="text" v-model="data.remark"  />
          </div>
           
           
    
      
          <div class="input-field center" v-if="validate && data.is_editable">
            <button
              v-if="data.finish == 1 || data.finish == 2"
              style="margin-left:20px;"
              :disabled="action_loading"
              type="button"
              class="btn green darken-3"
              @click="recoverData"
            >
              Recover
            </button>
             
            <button
          
            <button
              style="margin-left:20px;"
              :disabled="action_loading"
              type="button"
              class="btn blue darken-3"
              @click="submitData"
            >
              Save
            </button>
            <button
              style="margin-left:20px;"
              :disabled="action_loading"
              type="button"
              class="btn red darken-3"
              @click="deleteData"
            >
              Delete
            </button>
          </div>
          <div
      v-if="
        data.patient &&  data.patient.tb_conclusion && (
        data.patient.tb_conclusion.code ||
          data.patient.tb_conclusion.other_tb_code ||
          data.patient.tb_conclusion.temporary_code)
      "
    >
      <h6>Old TB Code</h6>
      <div class="row">
        <div class="input-field col s12">
          <span>TB / MDRTB / TPT code (Tsp)</span>
          <input type="text" v-model="data.patient.tb_conclusion.code" disabled />
        </div>
      </div>
      <div class="row">
      <div class="input-field col s12">
        <span>TB Code (Other) </span>
        <input type="text" v-model="data.patient.tb_conclusion.other_tb_code" disabled />
      </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <span>Temporary code</span>
          <input type="text" v-model="data.patient.tb_conclusion.temporary_code" disabled />
        </div>
      </div>
      
    </div>
        </div>
      </section>
    </div>
   
    
  </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import SettingBar from "./SettingBar.vue";
export default {
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    this.$store.dispatch("getDOTSPatientEditData", {
      id: this.$route.params.id,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    data() {
      // this.$store.dispatch(
      //   "getTownshipsByProject",
      //   this.$store.getters.dots_initial_data.project_id ?? 1
      // );
      return this.$store.getters.dots_initial_data;
    },
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    finish() {
      return this.$store.getters.dots_action_finish;
    },
    delete_finish() {
      return this.$store.getters.dots_delete_finish;
    },
    townships() {
      return this.$store.getters.townships;
    },
    validate() {
      if (this.data.is_new == 0) {
        if (
          this.data.name &&
          this.data.age &&
          this.data.gender &&
          this.data.address &&
          this.data.phone &&
          this.data.treatment_startdate &&
          this.data.dots_startdate
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.data.name &&
          this.data.age &&
          this.data.gender &&
          this.data.address &&
          this.data.phone &&
          this.data.weight &&
          this.data.tb_code &&
          this.data.treatment_startdate &&
          this.data.dots_startdate
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    submitData() {
      var date1 = new Date(this.data.treatment_startdate);
      var date2 = new Date(this.data.dots_startdate);
      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      
      if (diffDays >= 0) {
        this.data.id = this.$route.params.id;
        this.$store.dispatch("editDOTSPatient", this.data);
      } else {
        this.$notify({
          group: "noti",
          type: "danger",
          text: "DOTS Start date should be greater than Treatment Startdate",
        });
      }
    },
    getTownshipByProject() {
      this.$store.dispatch("getTownshipsByProject", this.data.project_id);
    },
    deleteData() {
      this.$store.dispatch("deleteDOTSPatient", this.data.id);
    },
    recoverData() {
      this.$store.dispatch("recoverDOTSEnd", {
        id: this.$route.params.id,
      });
    },
  },
  watch: {
    finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Updated",
        });
      }
    },
    delete_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Deleted",
        });

        this.$router.push("/patient/dots/list");
      }
    },
  },
  components: {
    "back-container": BackContainer,
    "setting-bar": SettingBar,
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
